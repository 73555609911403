import React from "react";
import "@shopify/polaris/build/esm/styles.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "components/AuthContext";
import AppLayout from "components/AppLayout";
import Dashboard from "views/Dashboard";
import Login from "views/Login";
import ReviewImages from "views/ReviewImages";
import ReviewErrors from "views/ReviewErrors";
import Sharepoint from "views/SharepointAuth";
import Notifications from "views/Notifications";
import SFTPFetch from "views/LookupFile";

function App() {
  const { isAuthenticated, login, logout } = useAuth();

  if (isAuthenticated) {
    return (
      <AppLayout onLogout={logout}>
        <Routes>
          <Route path="*" element={<Navigate to="/dashboard" />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/lookup-file" element={<SFTPFetch />} />
          <Route path="/review-images" element={<ReviewImages />} />
          <Route path="/review-errors" element={<ReviewErrors />} />
          <Route path="/settings/sharepoint" element={<Sharepoint />} />
          <Route path="/settings/notifications" element={<Notifications />} />
        </Routes>
      </AppLayout>
    );
  } else {
    return <Login onLogin={login} />;
  }
}

export default App;
