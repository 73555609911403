import { Banner, Layout, LegacyCard, Loading, Page } from "@shopify/polaris";

import Table from "components/Table";
import { getLookupFile, LookupFileRow } from "helpers/lookupFile";

import { useState } from "react";
import { useToast } from "hooks/useToast";

function FetchImages() {
  const [lookupFile, setLookupFile] = useState<LookupFileRow[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { renderToast, triggerToast } = useToast();
  const [fetchStatus, setFetchStatus] = useState({
    status: "idle",
    title: "",
    message: "",
  });

  const handleFetch = async () => {
    setIsFetching(true);
    setFetchStatus(createFetchStatus("idle", "", ""));
    try {
      const response = await getLookupFile();
      const { data } = response;

      if (Array.isArray(data)) {
        setLookupFile(data);
        if (data.length === 0) {
          setFetchStatus(
            createFetchStatus(
              "info",
              "Fetch completed.",
              "Lookup file is empty.",
            ),
          );
          triggerToast("Fetch completed");
        }
      } else {
        console.error("Error fetching files:", data);
        setFetchStatus(
          createFetchStatus(
            "critical",
            "Fetch Failed.",
            "Error fetching files.",
          ),
        );
      }
      triggerToast("Fetch completed");
    } catch (error: any) {
      console.error("Error fetching files:", error);
      const status = error.response?.status;
      let message = "";
      switch (status) {
        case 500:
          message = "SFTP server is not available.";
          break;
        case 401:
          message = "Unauthorized.";
          break;
        case 403:
          message = "Forbidden.";
          break;
        case 404:
          message = "Not found.";
          break;
        default:
          message = "Unknown error.";
          break;
      }
      setFetchStatus(createFetchStatus("critical", "Fetch Failed.", message));
      setLookupFile([]);
      triggerToast("Error retrieving the file", true);
    } finally {
      setIsFetching(false);
    }
  };

  const createFetchStatus = (
    status: string,
    title: string,
    message: string,
  ) => ({
    status,
    title,
    message,
  });

  return (
    <Page
      fullWidth
      title="Lookup File Retrieval"
      primaryAction={{
        content: "Retrieve Now",
        disabled: isFetching,
        loading: isFetching,
        onAction: () => handleFetch(),
      }}
    >
      <p>/net@work/NetSuite Lot Information/AT Item Lot Master.csv</p>
      {isFetching && <Loading />}
      <Layout>
        {renderToast()}
        <Layout.Section>
          {fetchStatus.status !== "idle" && (
            <Banner
              status={fetchStatus.status as any}
              title={fetchStatus.title}
              onDismiss={() => {
                setFetchStatus({
                  status: "idle",
                  title: "",
                  message: "",
                });
              }}
            >
              <p>{fetchStatus.message}</p>
            </Banner>
          )}
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <Table rowsPerPage={50} rows={lookupFile} loading={isFetching} />
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default FetchImages;
