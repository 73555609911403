export enum FlagCode {
  INVALID_NAME = "INVALID_NAME",
  INVALID_SKU = "INVALID_SKU",
  INVALID_LOT = "INVALID_LOT",
  NO_LOT = "NO_LOT",
  NO_DIMENSIONS = "NO_DIMENSIONS",
  NO_MATCH_LOT = "NO_MATCH_LOT",
  NO_MATCH_SKU = "NO_MATCH_SKU",
  NO_MATCH_DIMENSIONS = "NO_MATCH_DIMENSIONS",
  UNSUPPORTED = "UNSUPPORTED",
  IS_GALLERY = "IS_GALLERY",
  IS_VIDEO = "IS_VIDEO",
  IS_SLAB = "IS_SLAB",
  IS_TILE = "IS_TILE",
}

export enum FlagType {
  ATTENTION = "attention",
  CRITICAL = "critical",
  INFO = "info",
  SUCCESS = "success",
}

export type Flag = {
  type: FlagType;
  displayName: string;
  code: FlagCode;
};

export const flags: Flag[] = [
  // Invalid
  {
    type: FlagType.CRITICAL,
    displayName: "Invalid Name",
    code: FlagCode.INVALID_NAME,
  },
  {
    type: FlagType.CRITICAL,
    displayName: "Invalid SKU",
    code: FlagCode.INVALID_SKU,
  },
  {
    type: FlagType.CRITICAL,
    displayName: "Invalid Lot",
    code: FlagCode.INVALID_LOT,
  },
  {
    type: FlagType.CRITICAL,
    displayName: "Missing Lot",
    code: FlagCode.NO_LOT,
  },
  {
    type: FlagType.ATTENTION,
    displayName: "Missing Dimensions",
    code: FlagCode.NO_DIMENSIONS,
  },
  // No Match
  {
    type: FlagType.INFO,
    displayName: "SKU Not Found",
    code: FlagCode.NO_MATCH_SKU,
  },
  {
    type: FlagType.INFO,
    displayName: "Lot Not Found",
    code: FlagCode.NO_MATCH_LOT,
  },
  {
    type: FlagType.INFO,
    displayName: "Dimensions Not Found",
    code: FlagCode.NO_MATCH_DIMENSIONS,
  },
  {
    type: FlagType.CRITICAL,
    displayName: "Video",
    code: FlagCode.IS_VIDEO,
  },
  {
    type: FlagType.CRITICAL,
    displayName: "Gallery Image",
    code: FlagCode.IS_GALLERY,
  },
  {
    type: FlagType.CRITICAL,
    displayName: "Unsupported",
    code: FlagCode.UNSUPPORTED,
  },
  {
    type: FlagType.SUCCESS,
    displayName: "Slab",
    code: FlagCode.IS_SLAB,
  },
  {
    type: FlagType.SUCCESS,
    displayName: "Tile & Mosaic",
    code: FlagCode.IS_TILE,
  },
];

export function setFlag(flagCode: FlagCode) {
  return flags.find((flag) => flag.code === flagCode);
}

export function hasFlag(flags: Flag[], flagCode: FlagCode): boolean {
  return flags.some((flag) => flag.code === flagCode);
}

export function hasFlagType(flags: Flag[], flagType: FlagType): boolean {
  return flags.some((flag) => flag.type === flagType);
}
