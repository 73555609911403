import React from "react";
import { Link, List, Text, Banner, Layout, LegacyCard } from "@shopify/polaris";
import { SelectableText } from "./SelectableText";

interface PermissionsSectionProps {
  clientId: string;
  enabled?: boolean;
  grantAppPermissionsUrl: string;
  tenantName: string | null;
  appTitle: string;
}

const PermissionsSection: React.FC<PermissionsSectionProps> = ({
  clientId,
  enabled = false,
  grantAppPermissionsUrl,
  appTitle,
}) => {
  if (!enabled) {
    return (
      <Layout.Section>
        <LegacyCard title="Assign Permissions" sectioned>
          <Text as="p">Complete 'Register App' to assign permissions.</Text>
        </LegacyCard>
      </Layout.Section>
    );
  }

  return (
    <Layout.Section>
      <LegacyCard title="Grant Permissions" sectioned>
        <Banner hideIcon>
          <List type="number">
            <List.Item>
              Go to{" "}
              <Link
                external
                url="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade"
              >
                App registrations
              </Link>
            </List.Item>
            <List.Item>
              Click on <strong>All applications</strong> and select{" "}
              <strong>{appTitle}</strong>
            </List.Item>
            <List.Item>
              In the <b>Manage</b> tab, go to <strong>API permissions</strong>:
              <div style={{ margin: "8px 0 8px" }}>
                <List type="bullet">
                  <List.Item>
                    Click <strong>Add a permission</strong>.
                  </List.Item>
                  <List.Item>
                    Select <strong>SharePoint</strong>.
                  </List.Item>
                  <List.Item>
                    Choose <strong>Application permissions</strong>.
                  </List.Item>
                  <List.Item>
                    Check <strong>Sites.Selected</strong>.
                  </List.Item>
                  <List.Item>
                    Click <strong>Add permissions</strong>.
                  </List.Item>
                </List>
              </div>
            </List.Item>
            <List.Item>
              Click on <strong>Grant admin consent for MSFT</strong>.
            </List.Item>
          </List>
        </Banner>
      </LegacyCard>
    </Layout.Section>
  );
};

export default PermissionsSection;
