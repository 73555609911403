import {
  Badge,
  ExceptionList,
  Icon,
  IndexTable,
  Layout,
  LegacyCard,
  Link,
  List,
  Loading,
  Page,
  Select,
  Text,
} from "@shopify/polaris";
import {
  CircleInformationMajor,
  DiamondAlertMajor,
  EditMinor,
  FolderDownMajor,
  PauseCircleMajor,
  RefreshMinor,
  TickMinor,
} from "@shopify/polaris-icons";

import { Error as ErrorType } from "helpers/customTypes";
import { getImagesRootFolder } from "helpers/image";
import { useState, useEffect } from "react";
import authService from "helpers/AuthService";
import axios from "axios";
import SkeletonPage from "components/SkeletonPage";
import usePagination from "hooks/usePagination";

import exportCSV from "helpers/exportCSV";

import { useServerStatus } from "hooks/useServerStatus";

export enum StatusType {
  ERROR = "critical",
  IDLE = "info",
  IN_PROGRESS = "attention",
  LOADING = "info",
  SUCCESS = "success",
  TIMEOUT = "warning",
}

export type StatusProps = {
  type: StatusType;
  display: string;
  icon: any;
};

export const status: { [key in StatusType]: StatusProps } = {
  [StatusType.ERROR]: {
    type: StatusType.ERROR,
    display: "An error occurred while processing the images.",
    icon: DiamondAlertMajor,
  },
  [StatusType.LOADING]: {
    type: StatusType.LOADING,
    display: "Loading errors...",
    icon: FolderDownMajor,
  },
  [StatusType.IN_PROGRESS]: {
    type: StatusType.IN_PROGRESS,
    display: "Retrying errors...",
    icon: RefreshMinor,
  },
  [StatusType.SUCCESS]: {
    type: StatusType.SUCCESS,
    display: "Errors processed successfully.",
    icon: TickMinor,
  },
  [StatusType.TIMEOUT]: {
    type: StatusType.TIMEOUT,
    display:
      "The process will continue in the background, please wait for the email notification.",
    icon: CircleInformationMajor,
  },
  [StatusType.IDLE]: {
    type: StatusType.IDLE,
    display: "Idle",
    icon: PauseCircleMajor,
  },
};

const apiBase =
  process.env.REACT_APP_NODE_ENV !== "development"
    ? process.env.REACT_APP_API_BASE
    : "http://localhost:5000";

const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

const errorTypes = {
  MALFORMED_IMAGE: "MALFORMED_IMAGE",
  SHOPIFY_UPLOAD_ERROR: "SHOPIFY_UPLOAD_ERROR",
  SHAREPOINT_ERROR: "SHAREPOINT_ERROR",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  SFTP_ERROR: "SFTP_ERROR",
};

function ReviewErrors() {
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [state, setState] = useState<StatusProps>(status[StatusType.IDLE]);
  const { busy, renderStatus, serverData } = useServerStatus({
    startListening: true,
  });

  const [filterErrorType, setFilterErrorType] = useState(
    errorTypes.MALFORMED_IMAGE,
  );

  const handleFilterChange = (selectedErrorType: any) => {
    setFilterErrorType(selectedErrorType);
  };

  const errorsToDisplay = filterErrorType
    ? errors.filter((error) => error.type === filterErrorType)
    : errors;

  const filterOptions = Object.entries(errorTypes).map(([key, value]) => ({
    label: value.replaceAll("_", " "),
    value: key,
  }));

  const [errorCount, setErrorCount] = useState<number>(0);
  const [sharepointRootFolder, setSharepointRootFolder] = useState<string>("");
  const [task, setTask] = useState<string>("checkErrors");

  useEffect(() => {
    if (serverData?.data) {
      const { data } = serverData;
      if (task === "checkErrors") {
        const fetchedErrors = data?.errors as ErrorType[];
        if (!fetchedErrors) return;
        fetchedErrors.sort((a, b) => a.name.localeCompare(b.name));

        setErrorCount(fetchedErrors.length);
        setErrors(fetchedErrors);
        setState(status[StatusType.SUCCESS]);
        setIsLoading(false);
      }
    }
  }, [serverData, task]);

  const fetchErrors = async () => {
    setIsLoading(true);
    try {
      const {
        data: { errors: fetchedErrors },
      } = await axios.get(`${apiBase}/api/server/errors`, axiosConfig);

      setErrors(fetchedErrors);
      setErrorCount(fetchedErrors.length);
    } catch (error) {
      setErrors([]);
      setErrorCount(0);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkErrors = async () => {
    setIsLoading(true);
    setState(status[StatusType.LOADING]);
    setTask("checkErrors");

    try {
      await axios.get(`${apiBase}/api/operations/error-check`, axiosConfig);
      console.log("Checking errors process has started");
    } catch (error: any) {
      if (error?.response && error?.response?.status === 500) {
        console.log("Expected 500 error, checking errors process has started");
      } else {
        console.log("Unexpected error", error);
      }
    }
  };

  const exportErrors = async () => {
    const formattedDate = new Date().toISOString().replace(/[:.]/g, "-");
    const fileName = `Errors ${encodeURI(formattedDate)}.csv`;
    exportCSV({ errors, fileName, sharepointRootFolder });
  };

  const retryErrors = async () => {
    setIsLoading(true);
    setState(status[StatusType.IN_PROGRESS]);
    let finalStatus = status[StatusType.SUCCESS];

    try {
      await axios.get(`${apiBase}/api/operations/error-retry`, axiosConfig);
      setErrorCount(errors.length);
      console.log("Retry errors process has started");
    } catch (error: any) {
      if (error?.response && error?.response?.status === 500) {
        console.log("Expected 500 error, retry errors process has started");
        finalStatus = status[StatusType.SUCCESS];
      } else if (error?.response && error?.response.status === 504) {
        console.log("Timeout error", error);
        finalStatus = status[StatusType.TIMEOUT];
      } else {
        console.log("Unexpected error", error);
        finalStatus = status[StatusType.ERROR];
      }
    } finally {
      setIsLoading(false);
      setState(finalStatus);
    }
  };

  const handleDeleteAllErrors = async () => {
    console.log("Deleting all errors");
    try {
      await axios.get(`${apiBase}/api/errors/delete-all`, axiosConfig);
      await fetchErrors();
    } catch (error) {
      console.error(error);
    }
  };

  const handleNormalizeErrors = async () => {
    console.log("Normalizing errors");
    try {
      await axios.get(`${apiBase}/api/operations/error-normalize`, axiosConfig);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function setFolder() {
      const { root } = await getImagesRootFolder();
      setSharepointRootFolder(root);
      fetchErrors();
    }
    setFolder();
  }, []);

  const { displayData, renderPagination, renderPaginationCompact } =
    usePagination({
      data: errorsToDisplay,
      rowsPerPage: 50,
    });

  const rowMarkup = displayData.map(
    (
      { deleted, error, id, message, modified, modifiedName, name, type, url },
      index,
    ) => {
      const showEdit = type !== "SHOPIFY_UPLOAD_ERROR" && !deleted;
      const imageUrl = `${sharepointRootFolder}${url
        .replace(name, "")
        .replace(/#/g, "%23")}`;

      return (
        <IndexTable.Row id={id} key={id} position={index}>
          {/* Error Type */}
          <IndexTable.Cell>
            <Text variant="bodySm" as="span">
              {type?.replaceAll("_", " ")}
            </Text>
          </IndexTable.Cell>
          {/* Status */}
          <IndexTable.Cell>
            {deleted && <Badge status="critical">Deleted</Badge>}
            {modifiedName && <Badge status="attention">Modified</Badge>}
          </IndexTable.Cell>
          {/* Edit Image */}
          <IndexTable.Cell>
            {showEdit && (
              <Link external monochrome url={imageUrl}>
                <Icon source={EditMinor} color="subdued" />
              </Link>
            )}
          </IndexTable.Cell>
          {/* Image Name */}
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="bold" as="span">
              <p
                style={{
                  textDecoration:
                    modifiedName || deleted ? "line-through" : "none",
                }}
              >
                {name}
              </p>
              {modifiedName && <Text as="p">{modifiedName}</Text>}
            </Text>
          </IndexTable.Cell>
          {/* Error Message */}
          <IndexTable.Cell>
            <Text variant="bodySm" as="span">
              {message}
            </Text>
            {error && (
              <List spacing="extraTight" key={id}>
                {error.split(", ").map((reason, index) => (
                  <List.Item key={`${id}-${index}`}>
                    <Text variant="bodySm" as="span">
                      {reason}
                    </Text>
                  </List.Item>
                ))}
              </List>
            )}
          </IndexTable.Cell>
          {/* Modified */}
          <IndexTable.Cell>
            <Text as="p" variant="bodySm">
              {modified ?? ""}
            </Text>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    },
  );

  return (
    <Page
      fullWidth
      title={`Review Errors (${errorCount})`}
      primaryAction={{
        content: "Retry All",
        disabled: isLoading || errors.length === 0 || busy,
        onAction: () => retryErrors(),
      }}
      secondaryActions={[
        {
          content: "Export",
          disabled: isLoading || errors.length === 0,
          helpText: "Only exports the MALFORMED_IMAGE errors.",
          onAction: () => {
            exportErrors();
          },
        },
        {
          content: "Check for changes",
          disabled: isLoading || busy,
          loading: isLoading,
          onAction: () => {
            checkErrors();
          },
        },
      ]}
    >
      {isLoading && <Loading />}
      <Layout>
        {renderStatus()}
        {state.type !== StatusType.IDLE && (
          <Layout.Section>
            <ExceptionList
              items={[
                {
                  icon: state.icon,
                  description: state.display,
                },
              ]}
            />
          </Layout.Section>
        )}
        {isLoading && <SkeletonPage />}
        {!isLoading && (
          <>
            <Layout.Section>
              <LegacyCard>
                <div style={{ padding: "20px" }}>
                  <Select
                    label="Filter by Error Type"
                    options={filterOptions}
                    onChange={handleFilterChange}
                    value={filterErrorType}
                  />
                </div>
              </LegacyCard>
            </Layout.Section>

            <Layout.Section>
              <LegacyCard>
                <div
                  style={{
                    padding: "14px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text variant="bodyMd" as="legend">
                    {`Showing ${displayData.length} of ${errorsToDisplay.length} errors`}
                  </Text>
                  {renderPaginationCompact()}
                </div>
                <IndexTable
                  itemCount={errors.length}
                  selectable={false}
                  resourceName={{
                    singular: "error",
                    plural: "errors",
                  }}
                  headings={[
                    { title: "Type" },
                    { title: "Status" },
                    { title: "" },
                    { title: "Name" },
                    { title: "Details" },
                    { title: "Modified" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
                {renderPagination()}
              </LegacyCard>
            </Layout.Section>
          </>
        )}
        <Layout.Section>
          <button style={{ display: "none" }} onClick={handleDeleteAllErrors}>
            Delete All Errors
          </button>
        </Layout.Section>
        <Layout.Section>
          <button style={{ display: "none" }} onClick={handleNormalizeErrors}>
            Normalize Errors
          </button>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default ReviewErrors;
