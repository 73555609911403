import React from "react";
import {
  Grid,
  LegacyCard,
  VerticalStack,
  Text,
  Button,
} from "@shopify/polaris";
import { ExternalSmallMinor } from "@shopify/polaris-icons";

interface LinkCardProps {
  title: string;
  description: string;
  buttonText: string;
  url: string;
}

const LinkCard: React.FC<LinkCardProps> = ({
  title,
  description,
  buttonText,
  url,
}) => (
  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
    <LegacyCard
      title={
        <Text variant="headingSm" as="h2">
          {title}
        </Text>
      }
      sectioned
    >
      <VerticalStack gap="2">
        <Text as="p">{description}</Text>
        <div style={{ maxWidth: "350px" }}>
          <Button
            size="slim"
            fullWidth={false}
            url={url}
            external
            primary
            icon={ExternalSmallMinor}
          >
            {buttonText}
          </Button>
        </div>
      </VerticalStack>
    </LegacyCard>
  </Grid.Cell>
);

export default LinkCard;
