import { Layout, Loading, Page, Toast } from "@shopify/polaris";
import { Setting } from "helpers/customTypes";
import { useForm, FieldValues } from "react-hook-form";
import { useState, useEffect, useMemo, useCallback } from "react";
import authService from "helpers/AuthService";
import axios from "axios";
import AuthCodeSection from "components/AuthCodeSection";
import GetTenantIdSection from "components/GetTenantIdSection";
import PermissionsSection from "components/PermissionsSection";
import RegisterAppSection from "components/RegisterAppSection";
import SiteFoldersSection from "components/SiteFoldersSection";

const apiBase =
  process.env.REACT_APP_NODE_ENV !== "development"
    ? process.env.REACT_APP_API_BASE
    : "http://localhost:5000";

const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

function Settings() {
  const { control, handleSubmit, setValue, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Settings saved" onDismiss={toggleActive} duration={3000} />
  ) : null;

  const settingKeys: { [key: string]: keyof FieldValues } = {
    SHAREPOINT_AUTH_CODE: "authCode",
    SHAREPOINT_CLIENT_ID: "clientId",
    SHAREPOINT_CLIENT_SECRET: "clientSecret",
    SHAREPOINT_ROOT_FOLDER: "rootFolder",
    SHAREPOINT_SUBSITE: "subsite",
    SHAREPOINT_TENANT_ID: "tenantId",
    SHAREPOINT_TENANT_NAME: "tenantName",
    SHAREPOINT_TENANT_STRING: "tenantString",
  };

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    try {
      const sanitizedRootFolder = data?.rootFolder
        ?.trim()
        .replace(/^\/+|\/+$/g, "");

      const settingsToUpdate: Setting[] = Object.keys(settingKeys).map(
        (setting) => ({
          type: "sharepoint",
          name: setting,
          value:
            setting === "SHAREPOINT_ROOT_FOLDER"
              ? sanitizedRootFolder
              : data[settingKeys[setting]]?.trim(),
        }),
      );

      await Promise.allSettled(
        settingsToUpdate.map(async (setting) => {
          const url = `${apiBase}/api/settings/${setting.type}/${setting.name}`;
          const body = { setting_value: setting.value.trim() };

          await axios.put(url, body, axiosConfig);
        }),
      );
      setActive(true);
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `Error occurred while updating settings: ${error.message}`,
        );
      } else {
        console.error("An unexpected error occurred while updating settings.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const settingRequests = Object.keys(settingKeys).map(
          async (setting) => {
            const response = await axios.get(
              `${apiBase}/api/settings/sharepoint/${setting}`,
              axiosConfig,
            );
            const fieldKey = settingKeys[setting];
            setValue(fieldKey, response.data);
          },
        );

        await Promise.allSettled(settingRequests);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const tenantName = watch("tenantName");
  const subsite = watch("subsite");
  const rootFolder = watch("rootFolder");
  const clientId = watch("clientId");
  const clientSecret = watch("clientSecret");
  const tenantString = watch("tenantString");
  const tenantId = watch("tenantId");
  const appDomain =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? process.env.REACT_APP_SHAREPOINT_APP_DOMAIN || ""
      : "localhost:3000";
  const redirectUri =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? process.env.REACT_APP_SHAREPOINT_REDIRECT_URI || ""
      : "https://localhost:5000/api/sharepoint/auth";

  const appTitle =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? "Image Ingestor App"
      : "Image Ingestor App (Local)";

  useEffect(() => {
    if (tenantId) {
      setValue("tenantString", tenantId);
    }
  }, [setValue, tenantId]);

  const endpointUrl = useMemo(() => {
    if (!tenantName || !subsite) return "";
    return `https://${tenantName}.sharepoint.com/sites/${subsite}/`;
  }, [tenantName, subsite]);

  const registerAppUrl = useMemo(() => {
    if (!endpointUrl) return "";
    // return `${endpointUrl}_layouts/15/appregnew.aspx`;
    return `https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade`;
  }, [endpointUrl]);

  const grantAppPermissionsUrl = useMemo(() => {
    if (!endpointUrl) return "";
    return `${endpointUrl}_layouts/15/appinv.aspx`;
  }, [endpointUrl]);

  const tenantIdUrl = useMemo(() => {
    if (!endpointUrl) return "";
    return `${endpointUrl}_layouts/15/appprincipals.aspx`;
  }, [endpointUrl]);

  const authCodeUrl = useMemo(() => {
    if (!endpointUrl) return "";
    return `${endpointUrl}_layouts/15/OAuthAuthorize.aspx?client_id=${clientId}&scope=Web.Manage&response_type=code&redirect_uri=${redirectUri}`;
  }, [clientId, endpointUrl, redirectUri]);

  const rootFolderUrl = useMemo(() => {
    if (!rootFolder) return "";
    return `${endpointUrl}${rootFolder}/`;
  }, [endpointUrl, rootFolder]);

  const handleSave = async () => {
    setIsLoading(true);

    const settingsToUpdate = [
      {
        type: "sharepoint",
        name: "SHAREPOINT_CLIENT_ID",
        value: clientId?.trim(),
      },
      {
        type: "sharepoint",
        name: "SHAREPOINT_CLIENT_SECRET",
        value: clientSecret?.trim(),
      },
      {
        type: "sharepoint",
        name: "SHAREPOINT_TENANT_STRING",
        value: tenantString?.trim(),
      },
      {
        type: "sharepoint",
        name: "SHAREPOINT_TENANT_ID",
        value: tenantId?.trim(),
      },
    ];

    try {
      await Promise.all(
        settingsToUpdate.map(async (setting) => {
          const url = `${apiBase}/api/settings/${setting.type}/${setting.name}`;
          const body = { setting_value: setting.value };

          await axios.put(url, body, axiosConfig);
        }),
      );
      setActive(true);
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `Error occurred while updating settings: ${error.message}`,
        );
      } else {
        console.error("An unexpected error occurred while updating settings.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page title="Sharepoint Authentication">
      <Layout>
        <SiteFoldersSection
          control={control}
          rootFolderUrl={rootFolderUrl}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
        <RegisterAppSection
          appDomain={appDomain}
          appTitle={appTitle}
          control={control}
          enabled={tenantName && subsite && registerAppUrl}
          handleSave={handleSave}
          redirectUri={redirectUri}
          registerAppUrl={registerAppUrl}
          subsite={subsite}
          tenantName={tenantName}
          clientId={clientId || ""}
          authCodeUrl={authCodeUrl || ""}
        />
        <PermissionsSection
          enabled={
            clientId && clientSecret && tenantName && grantAppPermissionsUrl
          }
          appTitle={appTitle}
          clientId={clientId}
          grantAppPermissionsUrl={grantAppPermissionsUrl}
          tenantName={tenantName}
        />
        {/* <GetTenantIdSection
          enabled={clientId && tenantIdUrl}
          clientId={clientId}
          control={control}
          handleSave={handleSave}
          tenantIdUrl={tenantIdUrl}
        /> */}

        <AuthCodeSection
          enabled={clientId && tenantId && authCodeUrl}
          authCodeUrl={authCodeUrl}
          control={control}
          handleSave={handleSave}
        />
      </Layout>
      {toastMarkup}
      {isLoading && <Loading />}
    </Page>
  );
}

export default Settings;
