import React from "react";
import {
  Banner,
  Button,
  FormLayout,
  Layout,
  LegacyCard,
  Link,
  List,
  Text,
  VerticalStack,
} from "@shopify/polaris";

import { FormInput } from "./FormInput";
import { SelectableText } from "./SelectableText";

interface RegisterAppSectionProps {
  appDomain: string;
  appTitle: string;
  control: any;
  enabled?: boolean;
  handleSave: () => void;
  redirectUri: string;
  registerAppUrl: string;
  subsite: string | null;
  tenantName: string | null;
  clientId: string;
  authCodeUrl: string;
}

const RegisterAppSection: React.FC<RegisterAppSectionProps> = ({
  appDomain,
  appTitle,
  control,
  enabled = false,
  handleSave,
  redirectUri,
  registerAppUrl,
  clientId,
  authCodeUrl,
}) => {
  if (!enabled) {
    return (
      <Layout.Section>
        <LegacyCard title="Register App" sectioned>
          <Text as="p">
            Ensure 'Site & Folders' details are accurate to register this app.
          </Text>
        </LegacyCard>
      </Layout.Section>
    );
  }

  return (
    <Layout.Section>
      <LegacyCard title="Register Application" sectioned>
        <VerticalStack gap="5">
          <Banner hideIcon>
            <List type="number">
              <List.Item>
                Go to{" "}
                <Link external url={registerAppUrl}>
                  Register an App
                </Link>
              </List.Item>
              <List.Item>
                Click <strong>+ New registration</strong>
              </List.Item>
              <List.Item>
                Name the app: <SelectableText>{appTitle}</SelectableText>
              </List.Item>
              {/* <List.Item>
                App Domain: <SelectableText>{appDomain}</SelectableText>
              </List.Item> */}

              <List.Item>
                Select <SelectableText>Web</SelectableText> as the platform and
                set the Redirect URI to{" "}
                <SelectableText>{redirectUri}</SelectableText>
              </List.Item>

              <List.Item>
                Click <strong>Register</strong>
              </List.Item>
            </List>
          </Banner>
        </VerticalStack>
      </LegacyCard>

      <LegacyCard title="Retrieve Retrieve Application Details" sectioned>
        <VerticalStack gap="5">
          <Banner hideIcon>
            <List type="number">
              <List.Item>
                Go to{" "}
                <Link
                  external
                  url="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade"
                >
                  App registrations
                </Link>
              </List.Item>
              <List.Item>
                Click on <strong>All applications</strong> and select{" "}
                <strong>{appTitle}</strong>
              </List.Item>
              <List.Item>
                In the <strong>Overview</strong> tab:
                <div style={{ margin: "8px 0 8px" }}>
                  <List type="bullet">
                    <List.Item>
                      Copy the{" "}
                      <strong
                        style={{
                          color: "red",
                        }}
                      >
                        Application (client) ID{" "}
                      </strong>{" "}
                      and paste it below.
                    </List.Item>
                    <List.Item>
                      Copy the{" "}
                      <strong
                        style={{
                          color: "red",
                        }}
                      >
                        Directory (tenant) ID
                      </strong>{" "}
                      and paste it below.
                    </List.Item>
                  </List>
                </div>
              </List.Item>
              <List.Item>
                In the <b>Manage</b> tab, go to{" "}
                <b>{"Certificates & secrets"}</b> and click{" "}
                <b>+ New client secret</b>:
                <div style={{ margin: "8px 0 8px" }}>
                  <List type="bullet">
                    <List.Item>
                      (Optional) Add a description and select an expiration.
                    </List.Item>
                    <List.Item>
                      Click <strong>Add</strong>.
                    </List.Item>
                    <List.Item>
                      Copy the{" "}
                      <strong
                        style={{
                          color: "red",
                        }}
                      >
                        Value
                      </strong>{" "}
                      text of the <b>Client Secret</b> and paste it below.
                    </List.Item>
                  </List>
                </div>
              </List.Item>
            </List>
          </Banner>

          <FormLayout>
            <FormInput
              control={control}
              name="clientId"
              label="Application (client) ID"
              autoComplete="off"
              monospaced
            />
            <FormInput
              control={control}
              name="tenantId"
              label="Directory (tenant) ID"
              autoComplete="off"
              monospaced
            />

            <FormInput
              control={control}
              name="clientSecret"
              label="Client Secret (Value)"
              autoComplete="off"
              monospaced
            />

            <Button primary onClick={handleSave}>
              Save
            </Button>
          </FormLayout>
          <Banner hideIcon status="info">
            <p style={{ paddingBottom: "12px" }}>
              Regenerate Application <b>Client Secret</b>
            </p>
            <List type="number">
              <List.Item>
                Go to{" "}
                <Link
                  external
                  url={`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Credentials/appId/${clientId}`}
                >
                  {"Image Ingestor App > Manage > Certificates & Secrets"}
                </Link>
              </List.Item>
              <List.Item>
                Under <b>Client secrets</b>, delete the existing client secret.
              </List.Item>
              <List.Item>
                Click <strong>+ New client secret</strong>.
                <div style={{ margin: "8px 0 8px" }}>
                  <List type="bullet">
                    <List.Item>
                      Select <i>730 days (24 months)</i> as the expiration.
                    </List.Item>
                    <List.Item>
                      Click <strong>Add</strong>.
                    </List.Item>
                    <List.Item>
                      Copy the{" "}
                      <strong
                        style={{
                          color: "red",
                        }}
                      >
                        Value
                      </strong>{" "}
                      text of the new <b>Client Secret</b>.
                    </List.Item>
                  </List>
                </div>
              </List.Item>
              <List.Item>
                Paste the secret in the <b>Client Secret (Value)</b> field
                above.
              </List.Item>
              <List.Item>
                Click <b>Save</b>
              </List.Item>
              <List.Item>
                Go to{" "}
                <Link external url={authCodeUrl}>
                  OAuth Authorization
                </Link>{" "}
                and click 'Trust It'.
              </List.Item>
            </List>
          </Banner>
        </VerticalStack>
      </LegacyCard>
    </Layout.Section>
  );
};

export default RegisterAppSection;
