import {
  ExceptionList,
  Layout,
  LegacyCard,
  Loading,
  Page,
} from "@shopify/polaris";

import { fetchImages, getImagesRootFolder } from "helpers/image";
import { StatusType, StatusProps, status } from "helpers/status";
import { hasFlagType, FlagType } from "helpers/Flags";
import { Result } from "helpers/customTypes";
import { useState, useEffect } from "react";
import authService from "helpers/AuthService";
import axios from "axios";
import PagedResults from "components/PagedResults";
import SkeletonPage from "components/SkeletonPage";
import TabbedImages from "components/TabbedImages";

import { useServerStatus } from "hooks/useServerStatus";

const SERVER = `${process.env.REACT_APP_API_BASE}/api`;
const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

function ReviewImages() {
  const { busy, renderStatus, serverData, serverStatus, setListening } =
    useServerStatus({
      startListening: true,
    });

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [imageUrls, setImageUrls] = useState<Result[]>([]);
  const [results, setResults] = useState<any[]>([]);

  const [errorImages, setErrorImages] = useState<Result[]>([]);
  const [newImages, setNewImages] = useState<Result[]>([]);
  const [updateImages, setUpdateImages] = useState<Result[]>([]);

  const [sharepointRootFolder, setSharepointRootFolder] = useState<string>("");
  const [state, setState] = useState<StatusProps>(status[StatusType.IDLE]);

  const [task, setTask] = useState<string>("uploadImages");

  useEffect(() => {
    setListening(true);
  }, []);

  useEffect(() => {
    if (serverStatus) {
      switch (serverStatus) {
        case "COMPLETE":
          setState(status[StatusType.SUCCESS]);
          break;
        case "ERROR":
          setState(status[StatusType.ERROR]);
          setIsLoading(false);
          setIsUploading(false);
          break;
        case "BUSY":
          setState(status[StatusType.IN_PROGRESS]);
          break;
        default:
          setIsLoading(false);
          setIsUploading(false);
          setState(status[StatusType.IDLE]);
          break;
      }
    }
  }, [serverStatus]);

  useEffect(() => {
    if (serverData?.data) {
      const { data } = serverData;
      if (task === "fetchImages") {
        const rows = data?.images as Result[];
        if (!rows) return;

        const errorImages = rows.filter((item: Result) => !item.uploadable);

        const updateImages = rows.filter((item: Result) => item.uploadable);

        setImageUrls(rows);
        setUpdateImages(updateImages);
        setErrorImages(errorImages);
        setIsLoading(false);
        setIsUploading(false);
      }
      if (task === "uploadImages") {
        const results = data?.results as Result[];
        if (!results) return;
        setState(status[StatusType.SUCCESS]);
        setResults(results);
        setIsLoading(false);
        setIsUploading(false);
      }
    }
  }, [serverData, task]);

  useEffect(() => {
    const fetchSharepointRootFolder = async () => {
      try {
        const { root } = await getImagesRootFolder();
        setSharepointRootFolder(root);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSharepointRootFolder();
  }, []);

  async function getImages() {
    setIsLoading(true);
    setListening(true);
    setState(status[StatusType.LOADING]);
    setTask("fetchImages");

    try {
      await fetchImages();
      console.log("Fetching images process has started");
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        console.log("Expected 500 error, fetching images process has started");
      } else {
        console.log("Unexpected error", error);
      }
    } finally {
      setIsFirstRender(false);
    }
  }

  const handleUpload = async () => {
    setIsUploading(true);
    setListening(true);
    setTask("uploadImages");
    setState(status[StatusType.IN_PROGRESS]);
    try {
      await axios.get(`${SERVER}/operations/start`, axiosConfig);
      console.log("Process has started");
    } catch (error: any) {
      if (error?.response && error?.response?.status === 500) {
        console.log("Expected 500 error, process has started");
      } else {
        console.log("Unexpected error", error);
      }
    }
  };

  return (
    <Page
      fullWidth
      title="Review Images"
      primaryAction={{
        content: "Upload to Shopify",
        disabled: busy,
        loading: isUploading,
        onAction: () => handleUpload(),
      }}
      secondaryActions={[
        {
          content: "Scan folders",
          disabled: isLoading || isUploading || busy,
          loading: isLoading,
          onAction: () => {
            setResults([]);
            getImages();
          },
        },
      ]}
    >
      {(isLoading || isUploading) && <Loading />}

      <Layout>
        {renderStatus()}
        {isLoading && <SkeletonPage />}
        {!isLoading && (
          <>
            {state.type !== StatusType.IDLE && (
              <Layout.Section>
                <ExceptionList
                  items={[
                    {
                      icon: state.icon,
                      description: state.display,
                    },
                  ]}
                />
              </Layout.Section>
            )}
            {results.length > 0 && (
              <Layout.Section>
                <PagedResults
                  totalImages={imageUrls.length}
                  isUploading={isUploading}
                  results={results}
                />
              </Layout.Section>
            )}
            {!isFirstRender && serverData?.data && (
              <Layout.Section>
                <LegacyCard title={`Images (${imageUrls?.length || 0})`}>
                  <TabbedImages
                    rootFolder={sharepointRootFolder}
                    newImages={newImages}
                    updateImages={updateImages}
                    errorImages={errorImages}
                  />
                </LegacyCard>
              </Layout.Section>
            )}
          </>
        )}
      </Layout>
    </Page>
  );
}

export default ReviewImages;
