import {
  AppProvider,
  Box,
  Frame,
  Layout,
  LegacyCard,
  Loading,
  SkeletonBodyText,
  SkeletonPage,
  TopBar,
  VerticalStack,
} from "@shopify/polaris";
import { ArrowLeftMinor } from "@shopify/polaris-icons";
import { ReactNode } from "react";
import { useState, useCallback } from "react";
import NavigationMenu from "./NavigationMenu";

const loadingPageMarkup = (
  <SkeletonPage>
    <Layout>
      <Layout.Section>
        <VerticalStack gap="5">
          <LegacyCard>
            <Box padding="5">
              <SkeletonBodyText lines={9} />
            </Box>
          </LegacyCard>
        </VerticalStack>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);

function AppLayout({
  onLogout,
  children,
}: {
  onLogout: () => void;
  children: ReactNode;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive(
      (prevMobileNavigationActive) => !prevMobileNavigationActive,
    );
  }, []);

  const toggleIsUserMenuOpen = useCallback(() => {
    setIsUserMenuOpen((prevIsUserMenuOpen) => !prevIsUserMenuOpen);
  }, []);

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            { content: "Log out", icon: ArrowLeftMinor, onAction: onLogout },
          ],
        },
      ]}
      name="Admin"
      initials="A"
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchField={null}
      secondaryMenu={null}
      searchResultsVisible={false}
      searchResults={null}
      onSearchResultsDismiss={undefined}
      onNavigationToggle={undefined}
    ></TopBar>
  );

  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },

            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              navigationLabel: "Navigation",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          logo={{
            width: 32,
            topBarSource:
              "https://cdn.shopify.com/s/files/1/0272/6956/2456/files/logo_2_medium.png?width=50&grayscale=true",
            accessibilityLabel: "Artistic Tile Image Ingestor",
          }}
          topBar={topBarMarkup}
          navigation={<NavigationMenu setIsLoading={setIsLoading} />}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
        >
          <Box paddingBlockEnd="10">
            {isLoading ? <Loading /> : null}
            {isLoading ? <>{loadingPageMarkup}</> : children}
          </Box>
        </Frame>
      </AppProvider>
    </div>
  );
}

export default AppLayout;
