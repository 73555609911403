import React, { useState, useCallback } from "react";
import { Toast } from "@shopify/polaris";

interface ToastState {
  active: boolean;
  content: string;
  error?: boolean;
}

interface UseToastHook {
  renderToast: () => JSX.Element | null;
  triggerToast: (content: string, error?: boolean, duration?: number) => void;
}

export function useToast(): UseToastHook {
  const [toastState, setToastState] = useState<ToastState>({
    active: false,
    content: "",
  });

  const triggerToast = useCallback(
    (
      content: string,
      error: boolean = false,
      duration: number = 3000
    ): void => {
      setToastState({ active: true, content, error });

      setTimeout(() => {
        setToastState({ active: false, content: "", error: false });
      }, duration);
    },
    []
  );

  const renderToast = (): JSX.Element | null =>
    toastState.active ? (
      <Toast
        content={toastState.content}
        error={toastState.error}
        onDismiss={() =>
          setToastState({ active: false, content: "", error: false })
        }
      />
    ) : null;

  return { renderToast, triggerToast };
}
