import { Navigation } from "@shopify/polaris";

import {
  SettingsMajor,
  HomeMajor,
  ListMajor,
  ImagesMajor,
  CircleAlertMajor,
  InviteMinor,
  PasskeyMinor,
} from "@shopify/polaris-icons";

import { useCallback } from "react";
import { useLocation } from "react-router-dom";

interface NavItem {
  url: string;
  label: string;
  icon:
    | typeof HomeMajor
    | typeof ListMajor
    | typeof ImagesMajor
    | typeof SettingsMajor
    | typeof CircleAlertMajor;
  onClick: () => void;
  subNavigationItems?: NavItem[];
}

interface NavigationMenuProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ setIsLoading }) => {
  const location = useLocation();
  const navigationItems: NavItem[] = [
    {
      url: "/dashboard",
      label: "Dashboard",
      icon: HomeMajor,
      onClick: () => toggleIsLoading("/dashboard", location.pathname),
    },
    {
      url: "/lookup-file",
      label: "Lookup file",
      icon: ListMajor,
      onClick: () => toggleIsLoading("/lookup-file", location.pathname),
    },
    {
      url: "/review-images",
      label: "Review images",
      icon: ImagesMajor,
      onClick: () => toggleIsLoading("/review-images", location.pathname),
    },
    {
      url: "/review-errors",
      label: "Errors",
      icon: CircleAlertMajor,
      onClick: () => toggleIsLoading("/review-errors", location.pathname),
    },
    {
      url: "/settings/notifications",
      label: "Settings",
      icon: SettingsMajor,
      onClick: () => toggleIsLoading("/settings/sharepoint", location.pathname),
      subNavigationItems: [
        {
          url: "/settings/notifications",
          label: "Notifications",
          icon: InviteMinor,
          onClick: () =>
            toggleIsLoading("/settings/notifications", location.pathname),
        },
        {
          url: "/settings/sharepoint",
          label: "Sharepoint permissions",
          icon: PasskeyMinor,
          onClick: () =>
            toggleIsLoading("/settings/sharepoint", location.pathname),
        },
      ],
    },
  ];

  const toggleIsLoading = useCallback(
    (current: string, destination: string = "") => {
      if (current === destination) return;
      setIsLoading((prevIsLoading) => !prevIsLoading);
    },
    [setIsLoading]
  );

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section title="Image Ingestor App" items={navigationItems} />
    </Navigation>
  );
};

export default NavigationMenu;
