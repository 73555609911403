import {
  CircleInformationMajor,
  DiamondAlertMajor,
  FolderDownMajor,
  PauseCircleMajor,
  TickMinor,
  UploadMajor,
} from "@shopify/polaris-icons";

export enum StatusType {
  ERROR = "critical",
  IDLE = "info",
  IN_PROGRESS = "attention",
  LOADING = "info",
  SUCCESS = "success",
  TIMEOUT = "warning",
  TIMEOUT_ERROR = "critical",
}

export type StatusProps = {
  type: StatusType;
  display: string;
  icon: any;
};

export const status: { [key in StatusType]: StatusProps } = {
  [StatusType.ERROR]: {
    type: StatusType.ERROR,
    display: "An error occurred while processing the images.",
    icon: DiamondAlertMajor,
  },
  [StatusType.LOADING]: {
    type: StatusType.LOADING,
    display: "Loading images...",
    icon: FolderDownMajor,
  },
  [StatusType.IN_PROGRESS]: {
    type: StatusType.IN_PROGRESS,
    display: "Processing images...",
    icon: UploadMajor,
  },
  [StatusType.SUCCESS]: {
    type: StatusType.SUCCESS,
    display: "Images processed successfully.",
    icon: TickMinor,
  },
  [StatusType.TIMEOUT]: {
    type: StatusType.TIMEOUT,
    display:
      "The process will continue in the background, please wait for the email notification.",
    icon: CircleInformationMajor,
  },
  [StatusType.TIMEOUT_ERROR]: {
    type: StatusType.TIMEOUT_ERROR,
    display:
      "The server took too long fetching images from SharePoint, please try again later.",
    icon: DiamondAlertMajor,
  },
  [StatusType.IDLE]: {
    type: StatusType.IDLE,
    display: "Idle",
    icon: PauseCircleMajor,
  },
};
