import React from "react";
import { Button, Form, FormLayout, Layout, LegacyCard } from "@shopify/polaris";
import { FormInput } from "components/FormInput";
import { FieldValues } from "react-hook-form";

interface SiteFoldersProps {
  control: any;
  handleSubmit: (
    callback: (data: FieldValues) => Promise<void>
  ) => (event: React.FormEvent) => void;
  onSubmit: (data: FieldValues) => Promise<void>;
  rootFolderUrl?: string;
}

const SiteFoldersSection: React.FC<SiteFoldersProps> = ({
  rootFolderUrl,
  control,
  handleSubmit,
  onSubmit,
}) => (
  <Layout.Section>
    <LegacyCard title="Site & Folders" sectioned>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormLayout>
          <FormInput
            control={control}
            name="tenantName"
            label="Tenant Name"
            placeholder="artistictile"
            autoComplete="on"
          />

          <FormInput
            control={control}
            name="subsite"
            label="Subsite"
            placeholder="All"
            autoComplete="on"
          />

          <FormInput
            control={control}
            name="rootFolder"
            label="Root Folder"
            placeholder="MyFolder"
            autoComplete="on"
          />
          <Button
            external
            url={rootFolderUrl}
            disabled={!rootFolderUrl}
            size="slim"
          >
            View Folder
          </Button>

          <Button primary submit>
            Save
          </Button>
        </FormLayout>
      </Form>
    </LegacyCard>
  </Layout.Section>
);

export default SiteFoldersSection;
