import { Result } from "helpers/customTypes";
import {
  Badge,
  Box,
  ColumnContentType,
  DataTable,
  HorizontalStack,
  Link,
  ResourceItem,
  ResourceList,
  Text,
  VerticalStack,
} from "@shopify/polaris";
import { FlagType } from "helpers/Flags";
import { Status } from "@shopify/polaris/build/ts/latest/src/components/Badge";
import React from "react";
import usePagination from "hooks/usePagination";
import useFilters from "hooks/useFilters";

interface PagedResourceListProps {
  resourceUrls: Result[];
  rootFolder: string;
}

const PagedResourceList: React.FC<PagedResourceListProps> = ({
  resourceUrls,
  rootFolder,
}) => {
  const { renderedFilters, filteredResources } = useFilters(resourceUrls);

  const { displayData, renderPagination } = usePagination({
    data: filteredResources,
  });

  if (resourceUrls.length === 0) {
    return (
      <Text as="p" variant="headingMd">
        No resources found.
      </Text>
    );
  }

  return (
    <React.Fragment>
      <VerticalStack gap="2" align="start">
        <HorizontalStack gap="2" align="start">
          {renderedFilters}
        </HorizontalStack>
      </VerticalStack>
      <ResourceList
        headerContent={`Showing ${displayData.length} of ${resourceUrls.length} images`}
        showHeader
        items={displayData}
        renderItem={(item: Result) => {
          const { uniqueId, name, serverRelativeUrl, path } = item;
          const { LOT, SKU, SLAB_DIMENSIONS, FLAGS } = item.productDetails;
          const url = `${rootFolder}${serverRelativeUrl?.replace(/#/g, "%23")}`;

          const columnContentTypes: ColumnContentType[] = [
            "text",
            "text",
            "text",
          ];
          const headings = ["SKU", "LOT", "SLAB_DIMENSIONS"];

          return (
            <ResourceItem
              accessibilityLabel={`View details for ${name}`}
              external
              id={uniqueId}
              onClick={() => null}
            >
              <HorizontalStack gap="2" align="space-between">
                <Box maxWidth="50%">
                  <VerticalStack>
                    <Text variant="bodyMd" fontWeight="bold" as="h2">
                      {name}
                    </Text>

                    <Text variant="bodySm" as="p">
                      /{path}
                    </Text>

                    <Text as="p">
                      <Link
                        url={url.replace(name.replace(/#/g, "%23"), "")}
                        external
                      >
                        /{name}
                      </Link>
                    </Text>
                  </VerticalStack>
                </Box>
                <Box width="380px">
                  <VerticalStack gap="2" align="space-between">
                    <HorizontalStack gap="1" align="start">
                      {FLAGS.map((flag, index) => {
                        if (flag.type === FlagType.CRITICAL) {
                          return (
                            <Badge key={index} status={flag.type as Status}>
                              {flag.displayName}
                            </Badge>
                          );
                        }
                        return null;
                      })}
                      {FLAGS.map((flag, index) => {
                        if (flag.type === FlagType.ATTENTION) {
                          return (
                            <Badge key={index} status={flag.type as Status}>
                              {flag.displayName}
                            </Badge>
                          );
                        }
                        return null;
                      })}
                      {FLAGS.map((flag, index) => {
                        if (flag.type === FlagType.SUCCESS) {
                          return (
                            <Badge key={index} status={flag.type as Status}>
                              {flag.displayName}
                            </Badge>
                          );
                        }
                        return null;
                      })}
                      {FLAGS.map((flag, index) => {
                        if (flag.type === FlagType.INFO) {
                          return (
                            <Badge key={index} status={flag.type as Status}>
                              {flag.displayName}
                            </Badge>
                          );
                        }
                        return null;
                      })}
                    </HorizontalStack>
                    <DataTable
                      increasedTableDensity
                      columnContentTypes={columnContentTypes}
                      headings={headings}
                      rows={[[SKU, LOT, SLAB_DIMENSIONS]]}
                      hoverable={false}
                    />
                  </VerticalStack>
                </Box>
              </HorizontalStack>
            </ResourceItem>
          );
        }}
      />
      {renderPagination()}
    </React.Fragment>
  );
};

export default PagedResourceList;
