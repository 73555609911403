import { useState } from "react";
import { Layout, Pagination } from "@shopify/polaris";

interface PaginationProps<T> {
  data: T[];
  rowsPerPage?: number;
}

const usePagination = <T,>({ data, rowsPerPage = 100 }: PaginationProps<T>) => {
  if (rowsPerPage <= 0) {
    throw new Error("rowsPerPage must be a positive number");
  }

  const [activePage, setActivePage] = useState(1);
  const totalPages = Math.ceil((data.length || 0) / rowsPerPage);

  const handlePrevious = () => {
    setActivePage((prevActivePage) =>
      prevActivePage > 1 ? prevActivePage - 1 : prevActivePage,
    );
  };

  const handleNext = () => {
    setActivePage((prevActivePage) =>
      prevActivePage < totalPages ? prevActivePage + 1 : prevActivePage,
    );
  };

  const resetPagination = () => {
    if (activePage > totalPages) setActivePage(1);
  };

  const displayData = data.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage,
  );

  const renderPagination = () => (
    <Layout.Section>
      <div style={{ display: "grid", placeItems: "center", padding: "20px" }}>
        <Pagination
          hasPrevious={activePage > 1}
          onPrevious={handlePrevious}
          hasNext={activePage < totalPages}
          onNext={handleNext}
          label={`Page ${activePage} of ${totalPages || 1}`}
        />
      </div>
    </Layout.Section>
  );

  const renderPaginationCompact = () => (
    <div style={{ display: "grid", placeItems: "center" }}>
      <Pagination
        hasPrevious={activePage > 1}
        onPrevious={handlePrevious}
        hasNext={activePage < totalPages}
        onNext={handleNext}
      />
    </div>
  );

  return {
    activePage,
    displayData,
    renderPagination,
    resetPagination,
    renderPaginationCompact,
  };
};

export default usePagination;
