import { AppProvider, Frame, Box } from "@shopify/polaris";
import { ReactNode } from "react";

function LoginLayout({ children }: { children: ReactNode }) {
  return (
    <AppProvider
      i18n={{
        Polaris: {
          Avatar: {
            label: "Avatar",
            labelWithInitials: "Avatar with initials {initials}",
          },
          TextField: {
            characterCount: "{count} characters",
          },
        },
      }}
    >
      <Frame>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box width="100%" maxWidth="360px">
            {children}
          </Box>
        </div>
      </Frame>
    </AppProvider>
  );
}

export default LoginLayout;
