import { ParsedFile, MatchedImage } from "./customTypes";
import authService from "helpers/AuthService";
import axios from "axios";

export interface UploadImageParams {
  fileName: string;
  folder: string;
  imageName: string;
  productDetails: ParsedFile;
}

const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

type FetchImagesServer = {
  images: MatchedImage[];
};

const fetchImages = async (): Promise<FetchImagesServer> => {
  try {
    const {
      data: { images },
    } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/operations/fetch-images`,
      axiosConfig
    );
    return { images };
  } catch (error) {
    console.error(error);
    return { images: [] };
  }
};

const getImagesRootFolder = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_BASE}/api/sharepoint/folder`,
    axiosConfig
  );

  return data;
};

export { fetchImages, getImagesRootFolder };
