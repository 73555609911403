import { LookupFileRow } from "helpers/lookupFile";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import usePagination from "hooks/usePagination";
import {
  EmptySearchResult,
  IndexTable,
  Text,
  TextField,
} from "@shopify/polaris";

interface TableProps {
  loading: boolean;
  rowsPerPage?: number;
  rows?: Array<LookupFileRow>;
}

const imageKeys: (keyof LookupFileRow)[] = [
  "Lot Image 1",
  "Lot Image 2",
  "Lot Image 3",
  "Lot Image 4",
  "Lot Image 5",
  "Lot Image 6",
  "Lot Image 7",
  "Lot Image 8",
  "Lot Image 9",
  "Lot Image 10",
  "Lot Image 11",
  "Lot Image 12",
];

const initialRows: Array<LookupFileRow> = [];

function Table({ rowsPerPage = 20, rows = initialRows }: TableProps) {
  const [filterValue, setFilterValue] = useState("");

  const filteredRows = useMemo(() => {
    if (filterValue.trim() === "") {
      return rows;
    }

    const lowerCaseFilterValue = filterValue.trim().toLowerCase();
    return rows.filter((row) => {
      const item = row.Item?.trim().toLowerCase() || "";
      const internalID = row["Internal ID"]?.toString() || "";
      const lot = row.Lot?.toString().trim().toLowerCase() || "";

      return (
        item.includes(lowerCaseFilterValue) ||
        internalID.includes(filterValue) ||
        lot.includes(lowerCaseFilterValue)
      );
    });
  }, [filterValue, rows]);

  const {
    displayData: displayRows,
    renderPagination,
    renderPaginationCompact,
    resetPagination,
  } = usePagination({
    data: filteredRows,
    rowsPerPage,
  });

  const handleFilterChange = useCallback((value: string) => {
    setFilterValue(value);
  }, []);

  useEffect(() => {
    resetPagination();
  }, [filterValue, resetPagination]);

  const renderCell = useCallback(
    (row: LookupFileRow, key: keyof LookupFileRow) => (
      <IndexTable.Cell key={key?.toString()}>
        <Text variant="bodySm" as="span">
          {row[key]}
        </Text>
      </IndexTable.Cell>
    ),
    [],
  );

  const rowMarkup = displayRows.map((row, index) => {
    const id = uuidv4();
    return (
      <IndexTable.Row id={id} key={id} position={index}>
        {renderCell(row, "Item")}
        {renderCell(row, "Internal ID")}
        {renderCell(row, "Lot")}
        {renderCell(row, "Slab Dimensions")}
        {imageKeys.map((key) => renderCell(row, key))}
      </IndexTable.Row>
    );
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "24px",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 16px",
        }}
      >
        <div style={{ width: "50%" }}>
          <TextField
            label="Search"
            labelHidden
            value={filterValue}
            onChange={handleFilterChange}
            clearButton
            autoComplete="off"
            placeholder="Search by SKU, Internal ID, or Lot"
            onClearButtonClick={() => setFilterValue("")}
            disabled={rows.length === 0}
          />
        </div>

        {renderPaginationCompact()}
      </div>

      <IndexTable
        itemCount={filteredRows.length}
        emptyState={
          <EmptySearchResult
            title={"No rows found"}
            description={
              filterValue
                ? "Try changing the search term"
                : "Retrieve the Lookup file to see the rows"
            }
            withIllustration
          />
        }
        resourceName={{
          singular: "row",
          plural: "rows",
        }}
        selectable={false}
        headings={[
          { title: "SKU" },
          { title: "Internal ID" },
          { title: "Lot" },
          { title: "Slab Dimensions" },
          ...imageKeys.map((key) => ({ title: key })),
        ]}
      >
        {rowMarkup}
      </IndexTable>

      {renderPagination()}
    </>
  );
}

export default Table;
