export const findDimensions = (string: string) => {
  const regex = /\b(\d+\s*[xX]\s*\d+(\s*[xX]\s*\d+\w*)?)\b/;
  const match = string.match(regex);
  return match ? match[0].toUpperCase() : "";
};

export const findSKU = (fileName: string[]): string => {
  // Array of SKU finding strategies
  const skuStrategies = [
    {
      regex: /\b[A-Z0-9]{4,}\b/,
      findSKU: (match: string[], fileName: string[]) => {
        const SKU = match[0];
        // Confirm SKU by checking the other strings
        for (let i = 1; i < fileName.length; i++) {
          if (fileName[i].includes(SKU)) {
            return SKU;
          }
        }
        return null;
      },
    },
    {
      regex: /\b[A-Z0-9]{4,}\b/,
      findSKU: (match: string[], fileName: string[]) => {
        const SKU = match[0];
        // Check if SKU is at the end of the third string
        if (fileName[2].endsWith(SKU)) {
          return SKU;
        }
        return null;
      },
    },
  ];

  for (let strategy of skuStrategies) {
    for (let i = 0; i < fileName.length; i++) {
      const match = fileName[i].match(strategy.regex);
      if (match) {
        const SKU = strategy.findSKU(match, fileName);
        if (SKU) {
          return SKU;
        }
      }
    }
  }

  // If no SKU found with any strategy, return last word of the third string
  const words = fileName[2].split(" ");
  return words[words.length - 1];
};

export const findBlock = (strArray: string[]): string => {
  // Array of regular expressions and corresponding format functions
  const blockStrategies = [
    {
      // Matches pattern like "BLK# 5219 #53"
      regex: "BLK# ([\\w\\s]+) #(\\w+)",
      format: (match: string[]) => `${match[1].trim()}#${match[2]}`,
    },
    {
      // Matches pattern like "BLK#5219 #53"
      regex: "BLK#([\\w\\s]+) #(\\w+)",
      format: (match: string[]) => `${match[1].trim()}#${match[2]}`,
    },
    {
      // Matches pattern like "5219#53"
      regex: "([\\w\\s]+)#(\\w+)",
      format: (match: string[]) => `${match[1].trim()}#${match[2]}`,
    },
    {
      // Matches pattern like "5219 #53"
      regex: "([\\w\\s]+) #(\\w+)",
      format: (match: string[]) => `${match[1].trim()}#${match[2]}`,
    },
  ];

  for (let str of strArray) {
    for (let { regex: regexStr, format } of blockStrategies) {
      const regex = new RegExp(regexStr);
      const match = str.match(regex);
      if (match) {
        return format(match);
      }
    }
  }

  return "";
};
