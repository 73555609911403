import { LegacyCard, Tabs } from "@shopify/polaris";
import { useState, useCallback } from "react";
import PagedResourceList from "components/PagedResourceList";
import { Result } from "helpers/customTypes";

interface ReviewImagesProps {
  errorImages: Result[];
  newImages: Result[];
  updateImages: Result[];
  rootFolder: string;
}

const ReviewImages: React.FC<ReviewImagesProps> = ({
  errorImages,
  newImages,
  updateImages,
  rootFolder,
}) => {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    [],
  );

  const tabs = [
    {
      id: "updated-images",
      content: `Update rows (${updateImages.length})`,
      panelID: "updated-images-content",
    },
    {
      id: "error-images",
      content: `Malformed names (${errorImages.length})`,
      panelID: "error-images-content",
    },
  ];

  return (
    <Tabs tabs={tabs} selected={selected} fitted onSelect={handleTabChange}>
      <LegacyCard.Section>
        {selected === 0 && (
          <PagedResourceList
            rootFolder={rootFolder}
            resourceUrls={updateImages}
          />
        )}
        {selected === 1 && (
          <PagedResourceList
            rootFolder={rootFolder}
            resourceUrls={errorImages}
          />
        )}
      </LegacyCard.Section>
    </Tabs>
  );
};

export default ReviewImages;
