import { Badge, IndexTable, Text } from "@shopify/polaris";
import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import usePagination from "hooks/usePagination";
import { Result } from "helpers/customTypes";

enum Status {
  FULFILLED = "fulfilled",
  PENDING = "pending",
  REJECTED = "rejected",
}

const statusColorMap: { [key in Status]: "success" | "critical" | "info" } = {
  [Status.FULFILLED]: "success",
  [Status.REJECTED]: "critical",
  [Status.PENDING]: "info",
};

interface PagedIndexTableProps {
  results: Result[];
  headings: { title: string }[];
  id: string;
  rejected?: boolean;
}

const PagedIndexTable: React.FC<PagedIndexTableProps> = ({
  results,
  headings,
  id,
  rejected = false,
}) => {
  const { displayData, renderPagination } = usePagination({
    data: results,
    rowsPerPage: 50,
  });

  return (
    <>
      <IndexTable
        key={id}
        selectable={false}
        resourceName={{ singular: "result", plural: "results" }}
        itemCount={results.length}
        headings={headings as NonEmptyArray<{ title: string }>}
      >
        {displayData.map((item: Result, index: number) => {
          const {
            uniqueId,
            name,
            shopifyUrl,
            productDetails,
            error,
            fulfilled,
          } = item;
          let SKU, LOT, SLAB_DIMENSIONS;

          if (productDetails) {
            SKU = productDetails.SKU;
            LOT = productDetails.LOT;
            SLAB_DIMENSIONS = productDetails.SLAB_DIMENSIONS;
          }

          const displayName = !fulfilled ? name : SKU;
          const displayURL = !fulfilled ? error?.error ?? "" : shopifyUrl;

          let displayURLContent;
          if (displayURL && typeof displayURL === "string") {
            displayURLContent = !rejected ? (
              <a href={displayURL} target="_blank" rel="noreferrer">
                {displayURL}
              </a>
            ) : (
              <Text as="p" variant="bodyMd">
                {displayURL}
              </Text>
            );
          }

          return (
            <IndexTable.Row
              key={uniqueId}
              id={`${SKU}-${LOT}-${index}`}
              position={index}
            >
              <IndexTable.Cell>
                <Badge
                  status={
                    statusColorMap[
                      fulfilled ? Status.FULFILLED : Status.REJECTED
                    ]
                  }
                >
                  {fulfilled ? "Fulfilled" : "Rejected"}
                </Badge>
              </IndexTable.Cell>
              <IndexTable.Cell>{displayName}</IndexTable.Cell>
              {!rejected && (
                <>
                  <IndexTable.Cell>{LOT}</IndexTable.Cell>
                  <IndexTable.Cell>{SLAB_DIMENSIONS}</IndexTable.Cell>
                </>
              )}
              <IndexTable.Cell>{displayURLContent}</IndexTable.Cell>
            </IndexTable.Row>
          );
        })}
      </IndexTable>
      {renderPagination()}
    </>
  );
};

export default PagedIndexTable;
