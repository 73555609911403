import React from "react";
import {
  Banner,
  Layout,
  LegacyCard,
  Link,
  List,
  Text,
  VerticalStack,
} from "@shopify/polaris";

interface AuthCodeSectionProps {
  authCodeUrl: string;
  control: any;
  enabled?: boolean;
  handleSave: () => void;
}

const AuthCodeSection: React.FC<AuthCodeSectionProps> = ({
  authCodeUrl,
  control,
  enabled = false,
  handleSave,
}) => {
  if (!enabled) {
    return (
      <Layout.Section>
        <LegacyCard title="Get Auth Code" sectioned>
          <Text as="p">
            Ensure all prior sections are complete to obtain the Authorization
            code.
          </Text>
        </LegacyCard>
      </Layout.Section>
    );
  }
  return (
    <Layout.Section>
      <LegacyCard title="Get Auth Code" sectioned>
        <VerticalStack gap="5">
          <Banner hideIcon>
            <List type="number">
              <List.Item>
                Go to{" "}
                <Link external url={authCodeUrl}>
                  OAuth Authorization
                </Link>
              </List.Item>
              <List.Item>Click 'Trust It'</List.Item>
              {process.env.REACT_APP_NODE_ENV === "development" && (
                <Text as="p" variant="headingSm" color="critical">
                  *Replace 'https' with 'http' after being redirected.
                </Text>
              )}
            </List>
          </Banner>
        </VerticalStack>
      </LegacyCard>
    </Layout.Section>
  );
};

export default AuthCodeSection;
