import { useState, useEffect } from "react";
import { Tag } from "@shopify/polaris";
import { Flag, FlagCode, flags, hasFlag } from "helpers/Flags";
import { Result } from "helpers/customTypes";

const useFilters = (resourceUrls: Result[], defaultSelected: Flag[] = []) => {
  const [selectedFilters, setSelectedFilters] =
    useState<Flag[]>(defaultSelected);
  const [filteredResources, setFilteredResources] =
    useState<Result[]>(resourceUrls);

  const toggleFilter = (filter: Flag) => {
    if (hasFlag(selectedFilters, filter.code)) {
      setSelectedFilters((prev) => prev.filter((f) => f.code !== filter.code));
    } else {
      setSelectedFilters((prev) => [...prev, filter]);
    }
  };

  useEffect(() => {
    const filtered = resourceUrls.filter((item) => {
      const { FLAGS } = item.productDetails;
      const flags = FLAGS.map((flag) => flag.code);
      return selectedFilters.every((filter) => flags.includes(filter.code));
    });
    setFilteredResources(filtered);
  }, [selectedFilters, resourceUrls]);

  const renderedFilters = flags
    .filter(
      (flag) =>
        ![
          FlagCode.UNSUPPORTED,
          FlagCode.IS_VIDEO,
          FlagCode.IS_GALLERY,
        ].includes(flag.code),
    )
    .map((filter) => {
      const isSelected = hasFlag(selectedFilters, filter.code);

      return (
        <div
          key={filter.code}
          style={{
            cursor: "pointer",
            opacity: isSelected ? 1 : 0.5,
          }}
        >
          <Tag key={filter.code} onClick={() => toggleFilter(filter)}>
            {filter.displayName}
          </Tag>
        </div>
      );
    });

  return {
    selectedFilters,
    renderedFilters,
    filteredResources,
  };
};

export default useFilters;
