import React, { ReactNode } from "react";
import { Tag } from "@shopify/polaris";

interface SelectableTextProps {
  children: ReactNode;
}

export const SelectableText: React.FC<SelectableTextProps> = ({ children }) => (
  <Tag>
    <pre style={{ userSelect: "all", margin: 0 }}>
      <code>{children}</code>
    </pre>
  </Tag>
);
