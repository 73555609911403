import {
  Controller,
  Control,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
} from "react-hook-form";
import { TextField, TextFieldProps } from "@shopify/polaris";

interface FormInputProps<
  T extends FieldValues = FieldValues,
  K extends FieldPath<T> = FieldPath<T>
> extends Omit<
    ControllerRenderProps<T, K>,
    "onChange" | "onBlur" | "value" | "ref"
  > {
  label: string;
  placeholder?: string;
  autoComplete?: string;
  control: Control<T>;
  disabled?: boolean;
  monospaced?: boolean;
  multiline?: boolean;
  type?: TextFieldProps["type"]; // Use the type definition from Polaris TextFieldProps
  error?: string;
}

export function FormInput<
  T extends FieldValues = FieldValues,
  K extends FieldPath<T> = FieldPath<T>
>(props: FormInputProps<T, K>) {
  const {
    label,
    name,
    placeholder,
    autoComplete,
    control,
    type = "text",
    ...rest
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          label={label}
          placeholder={placeholder}
          autoComplete={autoComplete || "off"}
          type={type}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...rest}
        />
      )}
    />
  );
}
