import React, { useState } from "react";
import {
  Button,
  Form,
  FormLayout,
  LegacyCard,
  Text,
  Toast,
  VerticalStack,
} from "@shopify/polaris";
import { useForm, FieldValues } from "react-hook-form";
import { FormInput } from "components/FormInput";
import LoginLayout from "components/LoginLayout";

interface LoginProps {
  onLogin: (username: string, password: string) => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const { control, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (data: FieldValues) => {
    onLogin(data.username, data.password);
  };

  const dismissError = () => setErrorMessage("");

  return (
    <LoginLayout>
      <LegacyCard sectioned>
        <VerticalStack align="center" gap="8">
          <VerticalStack gap="2">
            <Text as="h1" variant="headingXl">
              Log In
            </Text>
            <Text color="subdued" as="p">
              Continue to the Artistic Tile app
            </Text>
          </VerticalStack>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormLayout>
              <FormInput
                control={control}
                name="username"
                label="Username"
                placeholder="Enter your username"
                autoComplete="on"
              />
              <FormInput
                control={control}
                name="password"
                label="Password"
                placeholder="Enter your password"
                autoComplete="on"
                type="password"
              />
              <Button fullWidth primary submit>
                Continue
              </Button>
            </FormLayout>
          </Form>
        </VerticalStack>
      </LegacyCard>
      {errorMessage && (
        <Toast
          error
          content={errorMessage}
          onDismiss={dismissError}
          duration={3000}
        />
      )}
    </LoginLayout>
  );
};

export default Login;
