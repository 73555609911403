import { ColumnContentType } from "@shopify/polaris";
import { findSKU, findBlock, findDimensions } from "helpers/regex";
import { Flag, setFlag, FlagCode } from "helpers/Flags";
import { LookupFileResult, ParsedFile, Image } from "helpers/customTypes";
import authService from "helpers/AuthService";
import axios from "axios";

const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

const getLookupFile = async (): Promise<LookupFileResult> => {
  const response = await fetchLookupFileFromAPI();

  if (response.error) {
    console.error("Error fetching files:", response.error);
    return { data: [], error: response.error };
  } else {
    return { data: response.data, error: false };
  }
};

const fetchLookupFileFromAPI = async (): Promise<LookupFileResult> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE}/api/sftp/lookup-file`,
    axiosConfig,
  );

  if (response.status === 200) {
    return { data: response.data.data, error: false };
  } else {
    return { data: [], error: response.data.error };
  }
};

const parseFilename = (file: Image) => {
  const parsedFile: ParsedFile = {
    LOT: "",
    name: file.Name,
    SKU: "",
    SLAB_DIMENSIONS: "",
    FLAGS: [] as Flag[],
  };

  const fileName = [...file.Path, file.Name]
    .reverse()
    .map((path) => path.toUpperCase().replaceAll("_", " "));

  // Regex patterns for SlabDimensions and File Extension
  const regexFileExtension = /\.[A-Za-z0-9]+$/;

  // Split filename using the first space
  const [SKU, remainder] = fileName[0].split(/ (.+)/);

  // Find SKU
  parsedFile.SKU = findSKU(fileName) || SKU;

  if (parsedFile.SKU.length < 5) {
    parsedFile.FLAGS.push(setFlag(FlagCode.INVALID_SKU)!);
  }

  // Find SlabDimensions
  fileName.forEach((path) => {
    if (parsedFile.SLAB_DIMENSIONS) return;
    const dimensions = findDimensions(path);
    if (dimensions) {
      parsedFile.SLAB_DIMENSIONS = dimensions;
    }
  });
  if (!parsedFile.SLAB_DIMENSIONS) {
    parsedFile.FLAGS.push(setFlag(FlagCode.NO_DIMENSIONS)!);
  }

  const newRemainder = remainder
    ?.replace(parsedFile?.SLAB_DIMENSIONS, "")
    .replace(parsedFile?.SKU, "")
    .trim();

  // Find Lot
  if (newRemainder) {
    const lot = newRemainder.replace(regexFileExtension, "").trim();
    parsedFile.LOT = findBlock([lot]);
  }

  if (!parsedFile.LOT) {
    parsedFile.FLAGS.push(setFlag(FlagCode.NO_LOT)!);
  }

  // Check if the filename is compliant
  if (
    !file.Name.includes(" ") ||
    (file.Name.includes(" ") && parsedFile.LOT === parsedFile.SLAB_DIMENSIONS)
  ) {
    parsedFile.FLAGS.push(setFlag(FlagCode.INVALID_NAME)!);
  }

  return parsedFile;
};

const matchImages = async (images: Image[]) => {
  const lookupFile = await getLookupFile();

  const parsedImages = images.map((image) => {
    const productDetails = parseFilename(image);
    return { ...image, productDetails };
  });

  const matchedImages = parsedImages.map((image) => {
    //filters the rows in the lookup file that match the SKU with 'Item' and LOT with 'Lot'
    const matchedRows = lookupFile.data.filter(
      (row) => row.Item === image.productDetails.SKU,
    );

    if (matchedRows.length === 0) {
      image.productDetails.FLAGS.push(setFlag(FlagCode.NO_MATCH_SKU)!);
    }

    const matchedLotRows = matchedRows.filter(
      (row) => row.Lot === image.productDetails.LOT,
    );

    if (image.productDetails.LOT && matchedLotRows.length === 0) {
      image.productDetails.FLAGS.push(setFlag(FlagCode.NO_MATCH_LOT)!);
    }

    return { ...image, matchedRows };
  });

  return matchedImages;
};

const LookupColumnMapping = [
  { name: "Item", type: "text" },
  { name: "Internal ID", type: "numeric" },
  { name: "Lot", type: "numeric" },
  { name: "Lot Image 1", type: "text" },
  { name: "Lot Image 2", type: "text" },
  { name: "Lot Image 3", type: "text" },
  { name: "Lot Image 4", type: "text" },
  { name: "Lot Image 5", type: "text" },
  { name: "Lot Image 6", type: "text" },
  { name: "Lot Image 7", type: "text" },
  { name: "Lot Image 8", type: "text" },
  { name: "Lot Image 9", type: "text" },
  { name: "Lot Image 10", type: "text" },
  { name: "Lot Image 11", type: "text" },
  { name: "Lot Image 12", type: "text" },
  { name: "Slab Dimensions", type: "text" },
];

const LookupColumnContentTypes = LookupColumnMapping.map(
  (column) => column.type,
) as ColumnContentType[];

const LookupColumnHeadings = LookupColumnMapping.map((column) => column.name);

export interface LookupFileRow {
  Item: string;
  "Internal ID": number;
  Lot: number;
  "Lot Image 1": string;
  "Lot Image 2": string;
  "Lot Image 3": string;
  "Lot Image 4": string;
  "Lot Image 5": string;
  "Lot Image 6": string;
  "Lot Image 7": string;
  "Lot Image 8": string;
  "Lot Image 9": string;
  "Lot Image 10": string;
  "Lot Image 11": string;
  "Lot Image 12": string;
  "Slab Dimensions": string;
}

export {
  getLookupFile,
  matchImages,
  LookupColumnContentTypes,
  LookupColumnHeadings,
};
