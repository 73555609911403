import { Error as ErrorType } from "helpers/customTypes";

interface CSVExportProps {
  errors: ErrorType[];
  fileName: string;
  sharepointRootFolder: string;
}

const exportCSV = ({
  errors,
  fileName,
  sharepointRootFolder,
}: CSVExportProps) => {
  const malformedErrors = errors.filter(
    (error) => error.type === "MALFORMED_IMAGE"
  );

  const exportData = malformedErrors.map(
    ({ name, message, error, url, updated }) => {
      const imageUrl = `${sharepointRootFolder}${url
        .replace(name, "")
        .replace(/#/g, "%23")}`;
      return {
        NAME: name,
        ERROR: message,
        DETAILS: error,
        URL: imageUrl,
        DATE: updated,
      };
    }
  );

  const replacer = (key: string, value: any) => (value === null ? "" : value);
  const header = Object.keys(exportData[0]);
  const csv = [
    header.join(","),
    ...exportData.map((row) =>
      header
        .map((fieldName) =>
          JSON.stringify(row[fieldName as keyof typeof row], replacer)
        )
        .join(",")
    ),
  ].join("\r\n");

  const blob = new Blob([csv], { type: "text/csv" });

  const link = document.createElement("a");

  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default exportCSV;
