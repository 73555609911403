const SERVER = `${process.env.REACT_APP_API_BASE}/api`;

class AuthService {
  private sessionStore: { [key: string]: string } = {};

  async login(username: string, password: string): Promise<string> {
    try {
      const response = await fetch(`${SERVER}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error("Invalid username or password");
      }

      const data = await response.json();
      const sessionId = data.token;
      this.sessionStore[sessionId] = username;

      localStorage.setItem("artistic_tile_image_ingestor_token", sessionId);

      return sessionId;
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  }

  logout(sessionId: string): void {
    delete this.sessionStore[sessionId];
    localStorage.removeItem("artistic_tile_image_ingestor_token");
  }

  isAuthenticated(sessionId: string): boolean {
    return this.sessionStore.hasOwnProperty(sessionId);
  }

  getAuthorizationHeader(sessionId: string): string {
    const token = sessionId;
    return `Bearer ${token}`;
  }

  getStoredToken(): string | null {
    return localStorage.getItem("artistic_tile_image_ingestor_token");
  }

  getStoredAuthorizationHeader(): string {
    const token = this.getStoredToken();
    return this.getAuthorizationHeader(token ?? "");
  }

  async fetchProtectedResource(route: string): Promise<any> {
    const sessionId = localStorage.getItem(
      "artistic_tile_image_ingestor_token"
    );
    const response = await fetch(`${SERVER}${route}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getAuthorizationHeader(sessionId ?? ""),
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Unable to fetch resource. Check your session.");
    }
  }
}

const authService = new AuthService();
export default authService;
