import { Text, Banner } from "@shopify/polaris";
import { useForm, FieldValues } from "react-hook-form";
import authService from "helpers/AuthService";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";

type Color = "success" | "warning" | "critical";

interface RefreshTokenCardProps {}

const apiBase =
  process.env.REACT_APP_NODE_ENV !== "development"
    ? process.env.REACT_APP_API_BASE
    : "http://localhost:5000";

const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

const redirectUri =
  process.env.REACT_APP_NODE_ENV !== "development"
    ? process.env.REACT_APP_SHAREPOINT_REDIRECT_URI || ""
    : "https://localhost:5000/api/sharepoint/auth";

const RefreshTokenCard: React.FC<RefreshTokenCardProps> = () => {
  const { setValue, watch } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [timerColor, setTimerColor] = useState<Color>("success");

  const clientId = watch("clientId");
  const refreshExpires = watch("refreshExpires");
  const subsite = watch("subsite");
  const tenantName = watch("tenantName");

  const settingKeys: { [key: string]: keyof FieldValues } = {
    SHAREPOINT_CLIENT_ID: "clientId",
    SHAREPOINT_REFRESH_EXPIRES: "refreshExpires",
    SHAREPOINT_SUBSITE: "subsite",
    SHAREPOINT_TENANT_NAME: "tenantName",
  };

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const settingRequests = Object.keys(settingKeys).map((setting) =>
          axios.get(
            `${apiBase}/api/settings/sharepoint/${setting}`,
            axiosConfig
          )
        );

        const responses = await axios.all(settingRequests);

        responses.forEach((response, index) => {
          const setting = Object.keys(settingKeys)[index];
          const fieldKey = settingKeys[setting];
          setValue(fieldKey, response.data);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const remainingTime = useMemo(() => {
    if (!refreshExpires) {
      setTimerColor("critical");
      return false;
    }
    const expiresDate = new Date(refreshExpires);
    const now = new Date();
    const diff = expiresDate.getTime() - now.getTime();
    const remaining = Math.ceil(diff / (1000 * 60 * 60 * 24));

    if (remaining >= 30) {
      setTimerColor("success");
    } else if (remaining >= 15) {
      setTimerColor("warning");
    } else {
      setTimerColor("critical");
    }

    return `The auth token expires in ${remaining} days`;
  }, [refreshExpires]);

  const authCodeUrl = useMemo(() => {
    if (!tenantName || !subsite || !clientId) return "";
    const endpointUrl = `https://${tenantName}.sharepoint.com/sites/${subsite}/`;
    return `${endpointUrl}_layouts/15/OAuthAuthorize.aspx?client_id=${clientId}&scope=Web.Manage&response_type=code&redirect_uri=${redirectUri}`;
  }, [tenantName, subsite, clientId]);

  if (isLoading) return null;

  return (
    <Banner
      title="Sharepoint Auth"
      action={{
        content: "Re-authorize",
        url: !remainingTime ? "/settings" : authCodeUrl,
        external: Boolean(remainingTime),
      }}
      status={timerColor}
    >
      <Text as="p">{remainingTime || "Token not set"}</Text>
    </Banner>
  );
};

export default RefreshTokenCard;
