import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import useAxios from "axios-hooks";
import authService from "helpers/AuthService";
import {
  Badge,
  BadgeProps,
  Grid,
  HorizontalStack,
  Layout,
  LegacyCard,
  Text,
} from "@shopify/polaris";

const apiBase = process.env.REACT_APP_API_BASE ?? "http://localhost:5000";
const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

enum ServerCode {
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  IDLE = "IDLE",
  PROCESSING = "BUSY",
}

const mapServerCodeToBadgeProps = (
  serverCode: ServerCode | null,
): BadgeProps => {
  switch (serverCode) {
    case ServerCode.COMPLETE:
      return { status: "success", progress: "complete" };
    case ServerCode.ERROR:
      return { status: "critical", progress: "incomplete" };
    case ServerCode.IDLE:
      return { status: "info", progress: "incomplete" };
    case ServerCode.PROCESSING:
      return { status: "warning", progress: "partiallyComplete" };
    default:
      return { status: "info" };
  }
};

export const useServerStatus = ({ startListening = false }) => {
  const [listening, setListening] = useState(startListening);
  const [status, setStatus] = useState<ServerCode | null>(null);
  const [currentTask, setCurrentTask] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [serverData, setServerData] = useState<any>(null);
  const [triggerData, setTriggerData] = useState<any>(false);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (!status) return;
    if (status === ServerCode.PROCESSING) {
      setBusy(true);
    } else {
      setBusy(false);
    }
    if (status === ServerCode.COMPLETE) {
      setTriggerData(true);
    }
  }, [status]);

  const [{ loading }, execute] = useAxios<{
    status: ServerCode;
    currentTask: string;
  }>(
    {
      url: `${apiBase}/api/server/status`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    },
    { manual: true },
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiBase}/api/server/data`,
          axiosConfig,
        );
        setServerData({ data: response.data.data });
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    if (triggerData) {
      fetchData();
      setTriggerData(false);
    }
  }, [triggerData]);

  const refetch = useCallback(async () => {
    try {
      const { data } = await execute();
      const newStatus = data?.status ?? null;
      const newCurrentTask = data?.currentTask ?? null;

      if (newStatus !== status || newCurrentTask !== currentTask) {
        setStatus(newStatus);
        setCurrentTask(newCurrentTask);
      }
    } catch (err: any) {
      setError(err.message);
    }
  }, [execute, status, currentTask]);

  useEffect(() => {
    if (listening) {
      refetch();
      const interval = setInterval(refetch, 5000);
      return () => clearInterval(interval);
    }
  }, [listening, refetch]);

  const renderStatus = useCallback(() => {
    if (!status) return null;
    return (
      <Layout.Section>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <LegacyCard
              title={
                <HorizontalStack align="space-between" gap="5">
                  <Text variant="headingSm" as="h2">
                    Server Status
                  </Text>
                  <Badge {...mapServerCodeToBadgeProps(status)}>
                    {status ?? ""}
                  </Badge>
                </HorizontalStack>
              }
            >
              <LegacyCard.Section>
                <div
                  className={status === ServerCode.PROCESSING ? "animated" : ""}
                >
                  {currentTask && (
                    <Text as="p" variant="bodySm">
                      {currentTask}
                    </Text>
                  )}
                </div>
              </LegacyCard.Section>
            </LegacyCard>
          </Grid.Cell>
        </Grid>
      </Layout.Section>
    );
  }, [status, currentTask]);

  return {
    busy,
    currentTask,
    error,
    listening,
    loading,
    renderStatus,
    serverData,
    setListening,
    serverStatus: status,
  };
};
