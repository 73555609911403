import {
  DescriptionList,
  LegacyCard,
  ProgressBar,
  Tabs,
} from "@shopify/polaris";

import PagedIndexTable from "components/PagedIndexTable";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Result } from "helpers/customTypes";

interface PagedResultsProps {
  results: Result[];
  isUploading: boolean;
  totalImages: number;
}

const PagedResults: React.FC<PagedResultsProps> = ({
  results,
  isUploading,
  totalImages = 0,
}) => {
  const [sortedResults, setSortedResults] = useState<Result[]>([]);
  const [rejectedResults, setRejectedResults] = useState<Result[]>([]);
  const [fulfilledResults, setFulfilledResults] = useState<Result[]>([]);

  useEffect(() => {
    const rejectedResults = results.filter((item: Result) => !item.fulfilled);

    const fulfilledResults = results.filter((item: Result) => item.fulfilled);
    setRejectedResults(rejectedResults);
    setFulfilledResults(fulfilledResults);

    setSortedResults([...rejectedResults, ...fulfilledResults]);
  }, [results]);

  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    [],
  );

  const tabs = [
    {
      id: "rejected-results",
      content: `Rejected (${rejectedResults.length})`,
      panelID: "rejected-results-content",
    },
    {
      id: "fulfilled-results",
      content: `Fulfilled (${fulfilledResults.length})`,
      panelID: "fulfilled-results-content",
    },
  ];

  const progress = useMemo(() => {
    if (!totalImages) return 0;
    return (sortedResults.length / totalImages) * 100;
  }, [totalImages, sortedResults]);

  return (
    <LegacyCard>
      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
        }}
      >
        <DescriptionList
          items={[
            {
              term: "Processed",
              description: `${results.length.toString()}`,
            },
          ]}
        />
        {isUploading && <ProgressBar progress={progress} color="success" />}
      </div>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <LegacyCard.Section>
          {selected === 0 && (
            <PagedIndexTable
              id="rejected-results-content"
              key="rejected-results-content"
              rejected
              results={rejectedResults}
              headings={[
                { title: "Status" },
                { title: "File Name" },
                { title: "Error" },
              ]}
            />
          )}
          {selected === 1 && (
            <PagedIndexTable
              id="fulfilled-results-content"
              key="fulfilled-results-content"
              results={fulfilledResults}
              headings={[
                { title: "Status" },
                { title: "SKU" },
                { title: "LOT" },
                { title: "DIMENSIONS" },
                { title: "Shopify URL" },
              ]}
            />
          )}
        </LegacyCard.Section>
      </Tabs>
    </LegacyCard>
  );
};

export default PagedResults;
