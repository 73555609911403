import {
  Button,
  EmptySearchResult,
  Form,
  FormLayout,
  IndexTable,
  Layout,
  LegacyCard,
  Loading,
  Page,
  Text,
  TextField,
} from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useToast } from "hooks/useToast";
import authService from "helpers/AuthService";
import axios from "axios";

const apiBase =
  process.env.REACT_APP_NODE_ENV !== "development"
    ? process.env.REACT_APP_API_BASE
    : "http://localhost:5000";

const getAxiosConfig = () => {
  const authHeader = authService.getStoredAuthorizationHeader();
  return {
    headers: { "Content-Type": "application/json", Authorization: authHeader },
  };
};

const apiEndpoint = `${apiBase}/api/settings/notification`;

interface EmailFormData {
  newEmail: string;
}

function Notifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const { renderToast, triggerToast } = useToast();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<EmailFormData>({
    mode: "onChange",
  });

  const getEmailList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${apiEndpoint}/EMAIL_LIST`,
        getAxiosConfig()
      );
      if (response.status >= 400) {
        throw new Error("Failed to fetch email list");
      }
      const parsedEmails = JSON.parse(response.data);
      setEmailList(parsedEmails);
    } catch (err) {
      triggerToast("Failed to fetch email list", true);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addEmail = async (data: EmailFormData) => {
    setIsLoading(true);
    try {
      const newEmail = data.newEmail.trim();
      const optimisticEmailList = [...emailList, newEmail];
      setEmailList(optimisticEmailList);

      const body = { setting_value: JSON.stringify(optimisticEmailList) };
      const response = await axios.put(
        `${apiEndpoint}/EMAIL_LIST`,
        body,
        getAxiosConfig()
      );
      if (response.status >= 400) {
        setEmailList(emailList);
        throw new Error("Failed to add email to the list");
      }
      triggerToast(`${newEmail} added to email list`);
    } catch (err) {
      setEmailList(emailList);
      triggerToast("Failed to add new email", true);
    } finally {
      setIsLoading(false);
      reset();
    }
  };

  const removeEmail = async (emailToRemove: string) => {
    setIsLoading(true);
    try {
      const updatedEmailList = emailList.filter(
        (email) => email !== emailToRemove
      );
      setEmailList(updatedEmailList);

      const body = { setting_value: JSON.stringify(updatedEmailList) };
      const response = await axios.put(
        `${apiEndpoint}/EMAIL_LIST`,
        body,
        getAxiosConfig()
      );
      if (response.status >= 400) {
        setEmailList(emailList);
        throw new Error("Failed to remove email from the list");
      }
      triggerToast(`${emailToRemove} deleted from email list`);
    } catch (err) {
      setEmailList(emailList);
      triggerToast("Failed to remove email", true);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmailList();
  }, []);

  const emailObjectList = emailList.map((email) => ({ email }));
  const rowMarkup = emailObjectList.map(({ email }, index) => {
    return (
      <IndexTable.Row id={email} key={email} position={index}>
        <IndexTable.Cell>
          <Text variant="bodySm" as="span">
            {email}
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Button
            size="slim"
            onClick={() => removeEmail(email)}
            icon={DeleteMinor}
          />
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <Page title="Email notification list">
      {renderToast()}

      <Layout>
        <Layout.Section>
          <Form onSubmit={handleSubmit(addEmail)}>
            <FormLayout>
              <FormLayout.Group condensed>
                <div style={{ minWidth: "300px" }}>
                  <Controller
                    control={control}
                    name="newEmail"
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Email address must be a valid address",
                      },
                      validate: {
                        emailNotExists: (value) =>
                          !emailList.includes(value.trim()) ||
                          "This email already exists in the list",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        labelHidden
                        type="email"
                        autoComplete="on"
                        placeholder="user@example.com"
                        error={errors.newEmail?.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Button
                    primary
                    submit
                    loading={isLoading}
                    disabled={isLoading || Boolean(errors.newEmail)}
                  >
                    Add Email
                  </Button>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Form>
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <IndexTable
              itemCount={emailObjectList.length}
              selectable={false}
              resourceName={{ singular: "email", plural: "emails" }}
              headings={[{ title: "Email" }, { title: "Delete" }]}
              emptyState={
                <EmptySearchResult
                  title="No Addresses Found"
                  description="Add email addresses using the form above."
                />
              }
            >
              {rowMarkup}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      {isLoading && <Loading />}
    </Page>
  );
}

export default Notifications;
