import { useEffect, useState, useCallback, useMemo } from "react";
import {
  Badge,
  Banner,
  Checkbox,
  Grid,
  HorizontalStack,
  Layout,
  LegacyCard,
  Loading,
  Page,
  Text,
  VerticalStack,
} from "@shopify/polaris";

import authService from "helpers/AuthService";
import axios from "axios";
import LinkCard from "components/LinkCard";
import RefreshTokenCard from "components/RefreshTokenCard";
import { useServerStatus } from "hooks/useServerStatus";

interface DashboardProps {
  userName?: string;
}

interface FieldValues {
  rootFolder: string;
  clientId: string;
  authCode: string;
  cronJobEnabled: boolean;
  cronJobLastRan: string;
  tenantName: string;
  subsite: string;
}

const apiBase = process.env.REACT_APP_API_BASE ?? "http://localhost:5000";
const authHeader = authService.getStoredAuthorizationHeader();
const axiosConfig = {
  headers: { "Content-Type": "application/json", Authorization: authHeader },
};

const Dashboard: React.FC<DashboardProps> = (props) => {
  const settingKeys: { [key: string]: keyof FieldValues } = {
    SHAREPOINT_ROOT_FOLDER: "rootFolder",
    SHAREPOINT_CLIENT_ID: "clientId",
    SHAREPOINT_AUTH_CODE: "authCode",
    SHAREPOINT_TENANT_NAME: "tenantName",
    SHAREPOINT_SUBSITE: "subsite",
  };

  const cronSettingKeys: { [key: string]: keyof FieldValues } = {
    CRON_JOB_ENABLED: "cronJobEnabled",
    CRON_JOB_LAST_SCAN: "cronJobLastRan",
  };

  const [isLoading, setIsLoading] = useState(true);

  const [hasSettings, setHasSettings] = useState(true);
  const [scheduledProcessingStatus, setScheduledProcessingStatus] =
    useState(false);
  const [lastJobRan, setLastJobRan] = useState("");
  const [sharepointRootFolder, setSharepointRootFolder] = useState("");
  const [sharepointTenantName, setSharepointTenantName] = useState("");
  const [sharepointSubsite, setSharepointSubsite] = useState("");
  const { renderStatus } = useServerStatus({
    startListening: true,
  });

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const fetchCronSettings = async () => {
          const cronjobRequests = Object.keys(cronSettingKeys).map(
            async (setting) => {
              try {
                const response = await axios.get(
                  `${apiBase}/api/settings/cronjob/${setting}`,
                  axiosConfig,
                );
                const settingValue = response?.data;
                switch (setting) {
                  case "CRON_JOB_ENABLED":
                    setScheduledProcessingStatus(settingValue === "true");
                    break;
                  case "CRON_JOB_LAST_SCAN":
                    setLastJobRan(settingValue);
                    break;
                }
              } catch (error) {
                let setting_value = "";
                switch (setting) {
                  case "CRON_JOB_ENABLED":
                    setting_value = "false";
                    setScheduledProcessingStatus(false);
                    break;
                  case "CRON_JOB_LAST_SCAN":
                    setting_value = "2000-01-01T00:00:00.000Z";
                    setLastJobRan(setting_value);
                    break;
                }
              }
            },
          );
          await Promise.all(cronjobRequests);
        };

        const fetchSharePointSettings = async () => {
          const settingRequests = Object.keys(settingKeys).map(
            async (setting) => {
              const response = await axios.get(
                `${apiBase}/api/settings/sharepoint/${setting}`,
                axiosConfig,
              );
              const settingValue = response?.data;
              switch (setting) {
                case "SHAREPOINT_ROOT_FOLDER":
                  setSharepointRootFolder(settingValue);
                  break;
                case "SHAREPOINT_TENANT_NAME":
                  setSharepointTenantName(settingValue);
                  break;
                case "SHAREPOINT_SUBSITE":
                  setSharepointSubsite(settingValue);
                  break;
              }
              if (!settingValue) {
                setHasSettings(false);
              }
            },
          );
          await Promise.all(settingRequests);
        };

        await Promise.all([fetchCronSettings(), fetchSharePointSettings()]);
      } catch (error) {
        console.error(error);
        setHasSettings(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const sharepointFolder = useMemo(() => {
    if (!sharepointRootFolder) return "";
    if (!sharepointTenantName) return "";
    if (!sharepointSubsite) return "";
    return `https://${sharepointTenantName}.sharepoint.com/sites/${sharepointSubsite}/${sharepointRootFolder}`;
  }, [sharepointRootFolder, sharepointTenantName, sharepointSubsite]);

  const handleToggle = useCallback(async (newChecked: boolean) => {
    setIsLoading(true);
    try {
      setScheduledProcessingStatus(newChecked);
      const url = `${apiBase}/api/settings/cronjob/CRON_JOB_ENABLED`;
      const body = { setting_value: newChecked.toString() };
      await axios.put(url, body, axiosConfig);
      window.location.reload();
    } catch (error) {
      console.error("Error updating the cronjob enabled status", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page title="Dashboard">
      <Layout>
        <Layout.Section>
          {!hasSettings && (
            <Banner
              title="Some required settings are empty"
              action={{
                content: "Update Settings",
                url: "/settings/sharepoint",
              }}
              status="critical"
            >
              <p>Please update your settings to ensure proper functionality.</p>
            </Banner>
          )}
        </Layout.Section>

        <Layout.Section>
          <RefreshTokenCard />
        </Layout.Section>

        <Layout.Section>
          <Grid>
            {sharepointFolder && (
              <LinkCard
                title="SharePoint Directory"
                description="Browse and organize your SharePoint documents."
                buttonText="Open SharePoint Directory"
                url={sharepointFolder}
              />
            )}

            <LinkCard
              title="Shopify File Manager"
              description="Manage and oversee your Shopify store's files."
              buttonText="Open Shopify File Manager"
              url={`https://admin.shopify.com/store/${process.env.REACT_APP_SHOP_NAME}/content/files?selectedView=all&order=id%20desc`}
            />
          </Grid>
        </Layout.Section>
        <Layout.Section>
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <LegacyCard
                title={
                  <HorizontalStack align="space-between" gap="5">
                    <Text variant="headingSm" as="h2">
                      Automated Image Processing
                    </Text>
                    <Badge
                      progress={
                        scheduledProcessingStatus ? "complete" : "incomplete"
                      }
                      status={
                        scheduledProcessingStatus ? "success" : "attention"
                      }
                    >
                      {scheduledProcessingStatus ? "Active" : "Inactive"}
                    </Badge>
                  </HorizontalStack>
                }
                sectioned
              >
                <VerticalStack gap="2">
                  <Checkbox
                    label="Enable Automated Image Processing (8:00 AM & 3:00 PM)"
                    checked={scheduledProcessingStatus}
                    onChange={handleToggle}
                  />
                  <Text as="h5" variant="bodySm" color="subdued">
                    {`Last processed: ${lastJobRan}`}
                  </Text>
                </VerticalStack>
              </LegacyCard>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Dashboard;
